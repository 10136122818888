import { observer } from "mobx-react-lite";
import { ProgressBar } from ".";
import useStores from "../hooks";
import { Recommendations } from "./Recommendations";
import { Recommendations as PrintRecommendations } from "./print/Recommendations";
import { H1 } from "./typo";

interface Props {
  sectionKey: string;
}
const ResultsBody = observer(({ sectionKey }: Props) => {
  const store = useStores();
  const section = store.data[sectionKey];

  const progressPercentage =
    (100 *
      (store.calculateSectionEmission(sectionKey) - section.minReference)) /
    (section.maxReference - section.minReference);

  const progressGermanyPercentage =
    (100 * (section.average - section.minReference)) /
    (section.maxReference - section.minReference);

  return (
    <div className="flex flex-col md:mt-8 divide-y-2 space-y-8">
      <div className="px-2">
        <ProgressBar
          showResultPin
          value={store.calculateSectionEmission(sectionKey)}
          pin={{
            progress: progressGermanyPercentage,
            title: "Durchschnitt Deutschland",
            value: section.average,
          }}
          progress={progressPercentage}
          isActive
        ></ProgressBar>
      </div>
      {/* <div>
        <div className="my-8">
          <H1 className="">Tipps & Tricks</H1>
          <span className="pt-2  ">
            Mit diesen Konkreten Handlungsempfehlungen können Sie Ihren
            Fußabdruck verringern
          </span>
        </div>
        {store.isInPrintMode ? (
            <PrintRecommendations sectionKey={sectionKey} />
          ) : (
            <Recommendations sectionKey={sectionKey} />
        )}
      </div> */}
    </div>
  );
});

export default ResultsBody;
