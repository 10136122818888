export const heatPowerCoefficients = [47.3, 53.1, 60.7, 66.8];

export const electricSourceCoefficients = [0.47, 0.0, 0.97857143];

export const energySourceCoefficients = [
  0.22571429,
  0.2735,
  0.24,
  1.0,
  0.22571429,
];

export const userWeightCoefficients = [
  45.0,
  52.5,
  70.0,
  90.0,
  110.0,
  130.0,
  140.0,
];

export const userSizeCoefficients = [130.0, 140.0, 160.0, 180.0, 200.0, 210.0];

export const userFitnessCoefficients = [0.9, 1.0, 1.1, 1.2];

export const userFoodCoefficients = [0.6, 0.74, 0.94, 1.0, 1.25];

export const userFoodRegionalityCoefficients = [0.69, 1.22, 1.43, 1.65, 1.98];

export const userFoodSustainabilityCoefficients = [0.95, 0.97, 1.0, 1.02, 1.04];

export const userColdBeverageCoefficients = [0.9, 1.0, 1.1, 1.2];

export const userHotBeverageCoefficients = [0.036, 0.091, 0.083, 0.208, 0];

export const userGenderCoefficients = [-161.0, 5.0, -83.0];

export const carTypeCoefficients = [0.18, 0.22, 0.32, 0.32, 0.09, 0.21];

export const vehicleFootprintCoefficients = [
  0.2783088,
  0.3838292,
  0.480414,
  0.480414,
  0.7817204,
  0.5292602,
];

export const userSecondHandCoefficients = [0.902, 0.952, 1.0];

export const userConsumptionBehCoefficients = [0.87, 0.93, 1.0, 1.15, 1.23];

export const userHobbyCoefficients = [0.022, 0.031, 0.05, 0.063, 0.072];

export const userPetsCoefficients = [0, 0.022, 0.08, 0.95, 1.8, 2.2];

export const userAgeCoefficients = [25.0, 32.5, 50.0, 60.0];
