import { isSliderInput } from "../util";
import { DataModel, ValueModel } from "../types";
import {
  electricSourceCoefficients,
  energySourceCoefficients,
  heatPowerCoefficients,
  userAgeCoefficients,
  userColdBeverageCoefficients,
  userConsumptionBehCoefficients,
  userFitnessCoefficients,
  userFoodCoefficients,
  userFoodRegionalityCoefficients,
  userFoodSustainabilityCoefficients,
  userGenderCoefficients,
  userHobbyCoefficients,
  userHotBeverageCoefficients,
  userPetsCoefficients,
  userSecondHandCoefficients,
  userSizeCoefficients,
  userWeightCoefficients,
} from "./coefficients";

export const calculateEffectiveFactor = (value: ValueModel) => {
  const range = isSliderInput(value.input)
    ? value.input.max - value.input.min
    : value.input.answers.length;
  return (
    (value.value - (value.baseValue ?? 0)) * (1 / range) * (value.factor ?? 1)
  );
};

export const data: DataModel = {
  living: {
    title: "Wohnen",
    icon: "icon-home",
    average: 2.74,
    averageLocal: 2.3,
    minReference: 0,
    maxReference: 5,
    hint: "This is a cool hint",
    values: {
      householdMembers: {
        title: "Wie viele Personen leben in deinem Haushalt?",
        value: 1,
        input: { min: 1, max: 10 },
      },
      livingArea: {
        title: "Wie groß ist deine/eure Wohnfläche?",
        value: 20,
        unit: "m²",
        input: { min: 20, max: 500 },
      },
      photovoltaics: {
        title:
          "Falls du/ihr eine Photovoltaik-Anlage besitzt, wie groß ist die Fläche?",
        value: 0,
        unit: "m²",
        input: { min: 0, max: 80 },
        footprint: (data: DataModel, value: ValueModel) => {
          const values = data.living.values;
          return (
            (-1 * (value.value * 1000.0 * 0.47)) /
            ((values.householdMembers as ValueModel).value * 10000.0)
          );
        },
      },
      electricityType: {
        title: "Welche Stromquelle ist die Richtige?",
        value: 2,
        input: {
          isCondensed: true,
          answers: [
            { text: "Strommix" },
            { text: "Ökostrom" },
            { text: "Fossil" },
          ],
        },
        hint: "Ökostrom: 100 % unabhängig von Kohle- und Atomkonzernen. </br> Strommix: 50% erneuerbar, 50% fossil </br> Fossil: reine Stromgewinnung aus Kohle, Kernenergie und Erdgas",
        footprint: (data: DataModel, value: ValueModel) => {
          const values = data.living.values;
          return (
            (electricSourceCoefficients[value.value] *
              (1600.0 +
                ((values.householdMembers as ValueModel).value - 1) *
                  0.83 *
                  1600.0)) /
            ((values.householdMembers as ValueModel).value * 1000.0)
          );
        },
      },

      heatingType: {
        title: "Welche Heizungsart nutzt du/ihr?",
        value: 0,
        input: {
          isCondensed: true,
          answers: [
            { text: "Erdgas" },
            { text: "Ölheizung" },
            { text: "Fernwärme" },
            { text: "Strom" },
            { text: "Sonstige" },
          ],
        },
        footprint: (data: DataModel, value: ValueModel) => {
          const values = data.living.values;
          let energySourceCoe;
          if ((values.electricityType as ValueModel).value === 3) {
            energySourceCoe =
              energySourceCoefficients[value.value] *
              electricSourceCoefficients[
                (values.electricityType as ValueModel).value
              ];
          } else {
            energySourceCoe = energySourceCoefficients[value.value];
          }
          return (
            ((values.livingArea as ValueModel).value *
              heatPowerCoefficients[
                (values.heatingPreferences as ValueModel).value
              ] *
              1500 *
              energySourceCoe) /
            ((values.householdMembers as ValueModel).value * 1000000.0)
          );
        },
      },
      heatingPreferences: {
        title: "Wie warm sind im Durchschnitt deine Räume?",
        value: 1,
        input: {
          isCondensed: true,
          answers: [
            { text: "sehr kalt", subtext: "15-16°" },
            { text: "eher kalt", subtext: "17-19°" },
            { text: "eher warm", subtext: "20-22°" },
            { text: "sehr warm", subtext: "23-25°" },
          ],
        },
      },
    },
  },
  nutrition: {
    title: "Ernährung",
    icon: "icon-food",
    average: 1.69,
    minReference: 0,
    maxReference: 3.3,
    values: {
      age: {
        title: "Wie alt bist du?",
        value: 1,
        input: {
          isCondensed: true,
          answers: [
            { text: "< 25 Jahre" },
            { text: "25 - 40 Jahre" },
            { text: "40 - 60 Jahre" },
            { text: "> 60 Jahre" },
          ],
        },
        // hint: "Im Alter sinkt Ihr Kalorienbedarf und somit auch Ihr CO2-Verbrauch",
      },
      gender: {
        title: "Geschlecht",
        value: 0,
        input: {
          isCondensed: true,
          answers: [
            { text: "weiblich" },
            { text: "männlich" },
            { text: "divers" },
          ],
        },
        hint: "Männer haben von Natur aus einen höheren Kalorienbedarf als Frauen",
      },
      fitness: {
        title: "Wie schätzt du dein Fitnesslevel ein?",
        value: 1,
        input: {
          isCondensed: true,
          answers: [
            { text: "bequem" },
            { text: "normal" },
            { text: "aktiv" },
            { text: "sehr aktiv" },
          ],
        },
        hint: "Je sportlicher ein Mensch ist, desto höher ist sein Bedarf an Kalorien und somit sein Lebensmittel-Verbrauch",
      },
      weight: {
        title: "Wie viel wiegst du?",
        value: 2,
        input: {
          isCondensed: true,
          answers: [
            { text: "< 45 kg" },
            { text: "45 - 60 kg" },
            { text: "60 - 80 kg" },
            { text: "80 - 100 kg" },
            { text: "100 - 120 kg" },
            { text: "120 - 140 kg" },
            { text: "> 140 kg" },
          ],
        },
      },
      size: {
        title: "Wie groß bist du?",
        value: 2,
        input: {
          isCondensed: true,
          answers: [
            { text: "< 130 cm" },
            { text: "130 - 150 cm" },
            { text: "150 - 170 cm" },
            { text: "170 - 190 cm" },
            { text: "190 - 210 cm" },
            { text: "> 210 cm" },
          ],
        },
      },
      nutritionMode: {
        title: "Welcher Ernährungsstil repräsentiert dich am besten?",
        value: 3,
        input: {
          answers: [
            {
              text: "vegan",
              // subtext: "keinerlei tierische Produkte oder Nebenprodukte",
            },
            {
              text: "vegetarisch",
              // subtext: "kein Fleisch, aber andere tierische Produkte",
            },
            {
              text: "selten Fleisch",
              // subtext: "1-2 Mal die Woche Fleisch, wenn möglich Bio",
            },
            {
              text: "gemischt",
              // subtext: "3-4 Mal die Woche Fleisch"
            },
            {
              text: "viel Fleisch",
              // subtext: "mehrmals täglich Fleisch, ohne Rücksicht auf Herkunft und Haltung",
            },
          ],
        },
        hint: "Viehhaltung ist weltweit für fast 20% aller Treibhausgasemissionen zuständig. </br> Der Konsum von Fleisch und tierischen Produkten wirkt sich somit unmittelbar auf Ihren CO2-Verbrauch aus.",
        footprint: (data: DataModel, value: ValueModel) => {
          const values = data.nutrition.values;
          return (
            ((10.0 *
              userWeightCoefficients[(values.weight as ValueModel).value] +
              6.25 * userSizeCoefficients[(values.size as ValueModel).value] +
              5.0 * userAgeCoefficients[(values.age as ValueModel).value] +
              userGenderCoefficients[(values.gender as ValueModel).value]) *
              userFitnessCoefficients[(values.fitness as ValueModel).value] *
              userFoodCoefficients[value.value] *
              userFoodRegionalityCoefficients[
                (values.regionalSaisonal as ValueModel).value
              ] *
              userFoodSustainabilityCoefficients[
                (values.bio as ValueModel).value
              ] *
              userColdBeverageCoefficients[
                (values.coldBeverages as ValueModel).value
              ] *
              0.6) /
              1000.0 +
            userHotBeverageCoefficients[
              (values.hotBeverages as ValueModel).value
            ]
          );
        },
      },
      regionalSaisonal: {
        title: "Wie häufig kaufst du regionale und saisonale Produkte ein?",
        value: 2,
        input: {
          isCondensed: true,
          answers: [
            { text: "immer" },
            { text: "meistens" },
            { text: "manchmal" },
            { text: "selten" },
            { text: "nie" },
          ],
        },
        hint: "Weite Transportwege von Lebensmitteln generieren sehr viel CO2 und a-saisonale Pflanzungen verbrauchen enorm viele Ressourcen.",
      },
      bio: {
        title: "Wie häufig kaufst du Bio-Produkte ein?",
        value: 2,
        input: {
          isCondensed: true,
          answers: [
            { text: "immer" },
            { text: "meistens" },
            { text: "manchmal" },
            { text: "selten" },
            { text: "nie" },
          ],
        },
      },
      coldBeverages: {
        title: "Welche der Kaltgetränke trinkst du am häufigsten?",
        value: 1,
        input: {
          answers: [
            {
              text: "Leitungswasser",
            },
            {
              text: "Mineralwasser",
            },
            {
              text: "Saft",
            },
            {
              text: "Limonade",
            },
          ],
        },
      },
      hotBeverages: {
        title: "Was beschreibt am besten deinen Heißgetränk-Konsum?",
        value: 0,
        input: {
          answers: [
            {
              text: "schwarzer Tee",
              subtext: "2 Tassen am Tag",
            },
            {
              text: "viel schwarzer Tee",
              subtext: "5 Tassen am Tag",
            },
            {
              text: "Kaffee",
              subtext: "2 Tassen am Tag",
            },
            {
              text: "viel Kaffee",
              subtext: "5 Tassen am Tag",
            },
            {
              text: "andere / keine",
            },
          ],
        },
      },
    },
  },
  mobility: {
    title: "Mobilität",
    icon: "icon-mobility",
    average: 2.09,
    minReference: 0,
    maxReference: 5,
    hint: "This is a cool hint",
    values: {
      transport: {
        title: "Transportmittel",
        subtitle:
          "Mit welchem Transportmittel fährst du am häufigsten? Und wie viele Kilometer fährst du damit?",
        values: {
          car: {
            isEnabled: true,
            title: "Auto",
            subtitle: "Fahrleistung mit dem eigenen Auto",
            value: 12000,
            unit: "km/Jahr",
            // hasElectricityOption: true,
            input: { min: 0, max: 50000 },
            footprint: (data: DataModel, value: ValueModel) => {
              return (0.22 * value.value) / 1000.0 + 0.3838292;
            },
          },
          carsharing: {
            title: "Carsharing oder Fahrgemeinschaften",
            subtitle:
              "Fahrleistung mit Carsharing-Autos oder Fahrgemeinschaften",
            value: 0,
            unit: "km/Jahr",
            // hasElectricityOption: true,
            input: { min: 0, max: 50000 },
            footprint: (data: DataModel, value: ValueModel) => {
              return (0.12 * value.value) / 1000.0;
            },
          },
          motorcycle: {
            title: "Motorrad / Motorroller",
            subtitle: "Fahrleistung mit dem Motorrad / Motorroller",
            value: 0,
            unit: "km/Jahr",
            // hasElectricityOption: true,
            input: { min: 0, max: 50000 },
            footprint: (data: DataModel, value: ValueModel) => {
              return (0.075 * value.value) / 1000.0 + 0.05298108;
            },
          },
          publicTransportation: {
            title: "Öffentliche Verkehrsmitteln",
            subtitle: "Fahrleistung mit öffentlichen Verkehrsmitteln",
            value: 0,
            unit: "km/Jahr",
            input: { min: 0, max: 10000 },
            footprint: (data: DataModel, value: ValueModel) => {
              return (0.05 * value.value) / 1000.0;
            },
          },
          train: {
            title: "Zugfahrten",
            subtitle: "Fahrleistung - Zugfahrten",
            value: 0,
            unit: "km/Jahr",
            input: { min: 0, max: 50000 },
            footprint: (data: DataModel, value: ValueModel) => {
              return (0.032 * value.value) / 1000.0;
            },
          },
          bicycle: {
            title: "Fahrrad",
            subtitle: "Fahrleistung mit dem Fahhrad",
            value: 0,
            unit: "km/Jahr",
            hasElectricityOption: true,
            input: { min: 0, max: 25000 },
          },
        },
      },
      continentalFlights: {
        title: "Wie viele Flugstunden reist du innerhalb Europas?",
        value: 0,
        unit: "Std/Jahr",
        input: { min: 0, max: 200 },
        footprint: (data: DataModel, value: ValueModel) => {
          return (0.23 * value.value * 665) / 1000.0;
        },
      },
      intercontinentalFlights: {
        title: "Wie viele Flugstunden reist du interkontinental?",
        value: 0,
        unit: "Std/Jahr",
        input: { min: 0, max: 200 },
        footprint: (data: DataModel, value: ValueModel) => {
          return (0.215 * value.value * 750) / 1000.0;
        },
      },
    },
  },
  consumption: {
    title: "Konsum",
    icon: "icon-shopping",
    average: 3.79,
    minReference: 0,
    maxReference: 6.5,
    hint: "This is a cool hint",
    values: {
      expenses: {
        title:
          "Höhe der monatlichen Ausgaben - abzüglich Miete und Lebensmittel?",
        subtitle:
          "enthält: Restaurant, Kino, Kleidung und Schuhe, technische Ausstattung, Möbel usw.",
        unit: "€",
        value: 400,
        input: { min: 0, max: 2500 },
        footprint: (data: DataModel, value: ValueModel) => {
          const values = data.consumption.values;
          return (
            0.00652956 *
            value.value *
            userSecondHandCoefficients[
              (values.secondHand as ValueModel).value
            ] *
            userConsumptionBehCoefficients[
              (values.consumptionBehavior as ValueModel).value
            ]
          );
        },
      },
      consumptionBehavior: {
        title: "Welches Konsumverhalten beschreibt deines am besten? ",
        subtitle:
          "Konsumierst du sparsam oder großzügig? Legst du Wert auf Langlebigkeit und Qualität oder auf günstige Preise, unabhängig von Herstellungskriterien? ",
        value: 2,
        input: {
          answers: [
            {
              text: "ich kaufe fast immer nachhaltig, umweltbewusst und fair trade ein",
            },
            { text: "ich versuche nachhaltig und umweltbewusst einzukaufen" },
            { text: "durchschnittlich" },
            { text: "könnte besser sein" },
            { text: "ist mir egal" },
          ],
        },
      },
      secondHand: {
        title:
          "Wie häufig kaufst du Second Hand (Kleidung, Möbel, technische Geräte)?",
        value: 1,
        input: {
          answers: [
            { text: "meistens" },
            { text: "manchmal" },
            { text: "nie" },
          ],
        },
        hint: "Je länger Konsumgüter leben dürfen, desto besser ist das für die Umwelt. </br> Bereits produzierte Waren verbrauchen im Weiterverkauf außerdem kaum CO2, lediglich für Transporte fallen dann noch Verbräuche an.",
      },
    },
  },
  hobbies: {
    title: "Freizeit",
    icon: "icon-hobbies",
    average: 0.9,
    minReference: 0,
    maxReference: 2.5,
    hint: "This is a cool hint",
    values: {
      hobbies: {
        title: "Wie umweltfreundlich sind deine Hobbys?",
        value: 2,
        input: {
          answers: [
            { text: "umweltfreundlich", subtext: "Singen, Lesen" },
            { text: "geringe Auswirkungen", subtext: "Wandern, Instrument" },
            { text: "durchschnittlich", subtext: "Computerspiele, Fotografie" },
            { text: "aufwändig", subtext: "Skifahren, Reiten" },
            { text: "sehr aufwändig", subtext: "Motorsport, Heliskiing" },
          ],
        },
        footprint: (data: DataModel, value: ValueModel) => {
          const values = data.nutrition.values;
          return (
            (userHobbyCoefficients[value.value] - 0.02) *
            userAgeCoefficients[(values.age as ValueModel).value]
          );
        },
      },
      pets: {
        title: "Welche Haustiere besitzt du?",
        value: 0,
        input: {
          answers: [
            { text: "keine" },
            { text: "Vogel" },
            { text: "Meerschweinchen" },
            { text: "Aquarium" },
            { text: "Hund (klein)" },
            { text: "Katze" },
          ],
        },
        hint: "Die Rohstoffe für Futter samt Herstellung, Verpackung und Transport sowie die CO2 Belastung durch Exkremente tragen nicht unmaßgeblich zu Ihrer persönlichen Ökobilanz bei.",
        footprint: (data: DataModel, value: ValueModel) => {
          return userPetsCoefficients[value.value];
        },
      },
    },
  },
  holidays: {
    title: "Urlaub",
    icon: "icon-vacation",
    average: 0.9,
    minReference: 0,
    maxReference: 2.5,
    hint: "This is a cool hint",
    values: {
      holiday: {
        title: "Wie machst du Urlaub?",
        values: {
          camping: {
            title: "Camping",
            subtitle: "Wie viele Wochen verbringst du im Camping-Urlaub?",
            value: 0,
            unit: "Wochen/Jahr",
            input: { min: 0, max: 10 },
            footprint: (data: DataModel, value: ValueModel) => {
              return 0.187 * value.value;
            },
          },
          couchsurfing: {
            title: "Couchsurfing",
            subtitle: "Wie viele Wochen verbringst du im Couchsurfing-Urlaub?",
            value: 0,
            unit: "Wochen/Jahr",
            input: { min: 0, max: 10 },
            footprint: (data: DataModel, value: ValueModel) => {
              return 0.113 * value.value;
            },
          },
          airbnb: {
            isEnabled: true,
            title: "Airbnb / Hotel",
            subtitle:
              "Wie viele Wochen verbringst du in Hotels/Airbnb-Wohnungen?",
            value: 2,
            unit: "Wochen/Jahr",
            input: { min: 0, max: 10 },
            footprint: (data: DataModel, value: ValueModel) => {
              return 0.367 * value.value;
            },
          },
          allInclusive: {
            title: "All-Inclusive-Urlaub",
            subtitle: "Wie viele Wochen verbringst du im All-Inclusive-Urlaub?",
            value: 0,
            unit: "Wochen/Jahr",
            input: { min: 0, max: 10 },
            footprint: (data: DataModel, value: ValueModel) => {
              return 0.577 * value.value;
            },
          },
          cruise: {
            title: "Kreuzfahrt-Reisen",
            subtitle: "Wie viele Wochen verbringst du auf Kreuzfahrt-Reisen?",
            value: 0,
            unit: "Wochen/Jahr",
            input: { min: 0, max: 10 },
            footprint: (data: DataModel, value: ValueModel) => {
              return 0.737 * value.value;
            },
            hint: "Auf einer siebentägigen Kreuzfahrt auf einem Schiff mit 2000- 3000 Passagieren zu zweit in einer Standardkabine sind Sie als einzelner Gast für 1,5 Tonnen CO2 verantwortlich. </br> Um 1,5 Tonnen CO2 aufzunehmen muss eine Buche 120 Jahre lang wachsen.",
          },
        },
      },
    },
  },
  digitalLiving: {
    title: "Digitales Leben",
    icon: "icon-digital",
    average: 0.9,
    minReference: 0,
    maxReference: 2.5,
    hint: "This is a cool hint",
    values: {
      streaming: {
        title:
          "Wie viele Stunden in der Woche steamst du Filme, Serien, Online-Videos?",
        value: 5,
        unit: "Stunden/Woche",
        input: { min: 0, max: 50 },
        footprint: (data: DataModel, value: ValueModel) => {
          return (213.0 + value.value * 0.64 * 52.0) / 1000.0;
        },
        hint: "Rohstoffbedarf, Energiebedarf und Wasserverbrauch eines Rechenzentrums sind nicht zu unterschätzen. </br> Vor allem die Klimaanlagen, die die Rechenzentren dauerhaft auf 25 Grad runterkühlen müssen, damit diese nicht überhitzen, fallen ins Gewicht.",
      },
    },
  },
  public: {
    title: "Öffentliche Emissionen",
    average: 0.73,
    icon: "icon-public",
    minReference: 0,
    maxReference: 1,
    hint: "Erklärung zu öffentlichen Emissionen - wie entstehen sie und wie könnten wir als Individuen etwas dazu beitragen, sie zu verbessern?",
    value: 0.73,
    values: {},
  },
};
