import React from "react";
import { asset } from "../util/assets";

export const Reference = () => {
  return (
    <div className="flex flex-row items-center justify-end p-4 space-x-4">
      <p className="text-gray-500 text-xs">powered by</p>
      <a href="https://www.worldwatchers.org" target="_blank" rel="noreferrer">
        <img
          src={asset(`logo-wow.svg`)}
          alt="Worldwatchers Icon"
          className="max-h-6"
        ></img>
      </a>
      <a href="https://www.twigbit.com" target="_blank" rel="noreferrer">
        <img
          src={asset(`logo-twigbit.svg`)}
          alt="twigbit Icon"
          className="max-h-6"
        ></img>
      </a>
    </div>
  );
};
